import BaseMethods from '../BaseMethods';

export default new (class extends BaseMethods {
    endpointRoute = '/api/v2/scopes/:scopeId/attachments';

    async download(scopeId, attachmentId) {
        this.setRouteBindings({scopeId});

        return this.asDownload(`${this.endpointRoute}/${attachmentId}/download`);
    }
})();
