<template>
  <CModal
    class="chat-popup"
    content-class-name="chat-popup__content"
    backdrop="static"
    :visible="visible"
    @close="closeChat"
  >
    <CModalHeader class="chat-popup__header" :close-button="false">
      <div class="chat-popup__title">
        <CModalTitle
          class="chat-popup__heading chat-popup__font chat-popup__font--h"
          >Chat

          <CBadge
            v-if="haveUnreadMessages"
            class="border border-light p-1 ml-2"
            color="danger"
            position="top-end"
            shape="rounded-circle"
            size="sm"
          >
            <span class="visually-hidden">New messages</span>
          </CBadge>
        </CModalTitle>

        <CButton
          style="padding: 0 !important"
          class="chat-popup__close"
          color="link"
          @click="closeChat"
        >
          <span class="chat-popup__font chat-popup__font--text">Close</span>

          <CIcon
            class="chat-popup__icon chat-popup__icon--arrow-right"
            size="custom"
            name="cilArRight"
          />
        </CButton>
      </div>

      <div class="chat-popup__tabs">
        <button
          type="button"
          class="chat-popup__button"
          :class="{
            'chat-popup__button--active': localActiveTab === 'scope_draft',
            'chat-popup__button--disabled': disabledScopeDraftTab,
          }"
          :disabled="disabledScopeDraftTab"
          @click="setActiveTab('scope_draft')"
        >
          Scope Draft
        </button>

        <button
          type="button"
          class="chat-popup__button"
          :class="{
            'chat-popup__button--active':
              localActiveTab === 'final_scope_approval',
            'chat-popup__button--disabled': disabledFinalScopeApprovalTab,
          }"
          :disabled="disabledFinalScopeApprovalTab"
          @click="setActiveTab('final_scope_approval')"
        >
          Final Scope Approval
        </button>
      </div>
    </CModalHeader>

    <CModalBody class="chat-popup__body">
      <div v-if="loadingMessages" class="chat-popup__spinner">
        <CSpinner color="secondary" size="lg" />
      </div>

      <CChat
        v-else
        :messages="messages"
        :config="chatConfig"
        :loading-messages-data="loadingMessages"
      />
    </CModalBody>
  </CModal>
</template>

<script>
import CChat from '@/components/CChat'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ChatPopup',
  components: { CChat },
  inject: ['toast'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    haveUnreadMessages: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
    activeTab: {
      type: String,
      default: '',
    },
    disabledScopeDraftTab: {
      type: Boolean,
      default: false,
    },
    disabledFinalScopeApprovalTab: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeChat'],
  data() {
    return {
      loadingMessages: false,

      localActiveTab: '',
    }
  },
  computed: {
    ...mapGetters({
      messages: 'messages',
    }),

    chatConfig() {
      return {
        scopeId: this.$route.params.scopeId,
        part: this.currentChatSlug,
      }
    },

    currentChatSlug() {
      const dictionary = {
        scope_draft: 'editing',
        final_scope_approval: 'approving',
      }

      return dictionary[this.localActiveTab]
    },
  },
  watch: {
    visible(visible) {
      if (visible) {
        document.body.style.overflow = 'hidden'
        this.fetchMessages(this.currentChatSlug)
      } else {
        document.body.style.overflow = 'visible'
      }
    },
    activeTab: {
      immediate: true,
      handler(slug) {
        this.setActiveTab(slug)
      },
    },
    currentChatSlug: {
      immediate: true,
      handler(slug) {
        if (slug && this.visible) {
          this.fetchMessages(slug)
        }
      },
    },
  },
  methods: {
    ...mapActions({
      getMessages: 'getMessages',
    }),

    fetchMessages(part) {
      this.loadingMessages = true
      this.getMessages({
        scope_id: this.$route.params.scopeId,
        part: part,
      }).finally(() => (this.loadingMessages = false))
    },
    setActiveTab(slug) {
      this.localActiveTab = slug
    },
    closeChat() {
      this.setActiveTab(this.activeTab)
      this.$emit('closeChat')
    },
  },
}
</script>

<style lang="scss">
.chat-popup {
  &.modal.modal-static .modal-dialog {
    transform: none;
  }

  .modal-dialog {
    margin: 0 0 0 auto;
    max-width: 350px;
  }

  &__content {
    overflow: hidden;
    border-radius: 8px 0 0 8px;
  }

  &__heading {
    position: relative;
  }

  &__font {
    margin: 0;

    &--h {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.002em;
      color: #1c262f;
    }

    &--text {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__tabs {
    display: flex;
    margin-top: 20px;
  }

  &__close {
    display: flex;
    align-items: center;
    color: #9da5b1;

    &:hover {
      color: #595e65;
    }
  }

  &__button {
    position: relative;
    background-color: transparent;
    padding: 0 0 9px;
    border: none;
    font-size: 16px;
    line-height: 24px;
    color: #1c262f;

    &:first-child {
      margin-right: 24px;
    }

    &--active {
      &::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #0081c2;
      }
    }

    &--disabled {
      color: #9aa8b5;
    }
  }

  &__icon {
    &--arrow-right {
      width: 8px;
      height: 8px;
      margin-left: 5px;
      margin-top: 3px;
    }
  }

  &__body {
    padding: 0;
    height: 65vh;
  }

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}
</style>
