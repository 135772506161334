<template>
  <CTooltip :content="tooltipText" placement="top">
    <template #toggler="{ on }">
      <span class="iconInfo" v-on="on">
        <svg
          id="Layer_1"
          enable-background="new 0 0 512 512"
          height="12px"
          version="1.1"
          viewBox="0 0 512 512"
          width="12px"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g>
            <path
              d="M257.338,166.245c16.297,0,29.52-13.223,29.52-29.52c0-16.317-13.223-29.501-29.52-29.501   c-16.298,0-29.52,13.185-29.52,29.501C227.818,153.022,241.04,166.245,257.338,166.245z"
              fill="#37404D"
            />
            <polygon
              fill="#37404D"
              points="277.383,205.605 277.383,195.265 277.383,185.925 218.343,185.925 218.343,205.605    238.023,205.605 238.023,372.885 218.343,372.885 218.343,392.565 297.063,392.565 297.063,372.885 277.383,372.885  "
            />
            <path
              d="M256.108,9.65c-135.857,0-246,110.143-246,246c0,135.877,110.143,246,246,246   c135.857,0,246-110.123,246-246C502.108,119.793,391.966,9.65,256.108,9.65z M256.108,481.97   c-124.797,0-226.32-101.533-226.32-226.32S131.312,29.33,256.108,29.33c124.797,0,226.32,101.533,226.32,226.32   S380.905,481.97,256.108,481.97z"
              fill="#37404D"
            />
          </g>
        </svg>
      </span>
    </template>
  </CTooltip>
</template>

<script>
export default {
  name: 'InfoIcon',
  props: {
    tooltipText: { type: String, default: '' },
  },
}
</script>

<style lang="scss" scoped>
.iconInfo {
  cursor: pointer;
  position: relative;
  top: -5px;
  left: 3px;
}
</style>
