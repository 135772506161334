<template>
  <ul class="work-list">
    <li
      v-for="(item, index) in localWorkList"
      :key="index"
      class="work-list__item"
    >
      <CNInput
        v-model="localWorkList[index].name"
        class="work-list__input work-list__input--work"
        label="Work Item"
        required
        :disabled="disabled"
        :invalid="!!validationErrors[`${index}_name`]"
        :error="validationErrors[`${index}_name`]"
        @blur="handleBlurOption('name', index)"
      />

      <CNInput
        v-model="localWorkList[index].unit"
        class="work-list__input work-list__input--unit"
        label="Unit"
        required
        :disabled="disabled"
        :invalid="!!validationErrors[`${index}_unit`]"
        :error="validationErrors[`${index}_unit`]"
        @blur="handleBlurOption('unit', index)"
      />

      <CNInput
        v-model="localWorkList[index].cost_code"
        class="work-list__input work-list__input--code"
        label="Cost Code"
        required
        :disabled="disabled"
        :invalid="!!validationErrors[`${index}_cost_code`]"
        :error="validationErrors[`${index}_cost_code`]"
        @blur="handleBlurOption('cost_code', index)"
      />

      <CNInput
        :model-value="localWorkList[index].amount"
        mask="money"
        :mask-money="{
          decimal: '.',
          thousands: ',',
          prefix: '$ ',
          precision: 2,
          masked: true,
        }"
        class="work-list__input work-list__input--amount"
        label="Amount"
        required
        :disabled="disabled"
        :invalid="!!validationErrors[`${index}_amount`]"
        :error="validationErrors[`${index}_amount`]"
        @input="changeAmount($event, index)"
        @blur="handleBlurOption('amount', index)"
      />

      <button
        type="button"
        class="work-list__delete-button"
        :class="{
          'work-list__delete-button--disabled': localWorkList.length <= 1,
        }"
        @click="deleteWorkItem(index)"
      >
        <CIcon class="work-list__icon" name="cilX" size="lg" />
      </button>
    </li>

    <li class="work-list__add">
      <button
        type="button"
        class="work-list__add-button"
        @click="addWorkItem"
      >
        <CIcon class="work-list__icon" name="cilPlus" size="lg" />
        <span class="work-list__add-text">Add work item</span>
      </button>
    </li>
  </ul>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import { permissionError } from '@/services/Notify/Toasts'

export default {
  name: 'WorkList',
  props: {
    workList: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateList'],
  data() {
    return {
      validationErrors: {},
      validator: {},

      localWorkList: [
        {
          name: '',
          unit: '',
          cost_code: '',
          amount: '',
        },
      ],
    }
  },
  watch: {
    workList: {
      immediate: true,
      handler(list) {
        if (!list.length) return

        this.localWorkList = list
      },
    },
    localWorkList: {
      handler(list) {
        this.setRules()
        this.$emit('updateList', list)
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => this.setRules())
  },
  methods: {
    modifyAmount(val) {
      return val.replace('$ ', '').replaceAll(',', '')
    },
    changeAmount(event, index) {
      this.localWorkList[index].amount = this.modifyAmount(event.target.value)
    },

    addWorkItem() {
      if (this.disabled) {
        this.$notify(permissionError)
        return
      }
      if (!this.validateAll()) return

      this.localWorkList = [
        ...this.localWorkList,
        {
          name: '',
          unit: '',
          cost_code: '',
          amount: '',
        },
      ]

      this.setRules()
    },
    deleteWorkItem(index) {
      if (this.disabled) {
        this.$notify(permissionError)
        return
      }
      if (this.localWorkList.length <= 1) return

      this.localWorkList = this.localWorkList.filter((el, i) => i !== index)

      this.validateAll()
      this.setRules()
    },

    validateAll() {
      const errorsList = []

      this.localWorkList.forEach((el, index) => {
        errorsList[`${index}_name`] = el.name
        errorsList[`${index}_unit`] = el.unit
        errorsList[`${index}_cost_code`] = el.cost_code
        errorsList[`${index}_amount`] = el.amount
      })

      this.setRules()
      const validationResult = this.validator.validateAll(errorsList)
      this.validationErrors = this.$deepClone(validationResult.validationErrors)

      return !validationResult.hasErrors
    },
    setRules() {
      let baseRules = {}

      if (this.localWorkList.length) {
        this.localWorkList.forEach((el, index) => {
          baseRules[`${index}_name`] = [rules.required, rules.strMax(255)]
          baseRules[`${index}_unit`] = [rules.required, rules.strMax(255)]
          baseRules[`${index}_cost_code`] = [rules.required, rules.strMax(255)]
          baseRules[`${index}_amount`] = [
            rules.required,
            rules.numMin(1),
            rules.numMax(1000000000000),
          ]
        })
      }

      this.validator = validator(baseRules)
    },
    handleBlurOption(key, index) {
      const errorKey = `${index}_${key}`

      this.validationErrors[errorKey] = this.validator.validate(
        errorKey,
        this.localWorkList[index][key],
      )
    },
  },
}
</script>

<style scoped lang="scss">
.work-list {
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    display: grid;
    grid-template-columns:
      1fr minmax(80px, auto) minmax(108px, auto) minmax(180px, auto)
      24px;
    gap: 12px;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &__add {
    margin-top: 16px;
  }

  &__delete-button,
  &__add-button {
    padding: 0;
    background: transparent;
    border: none;
    display: flex;
  }

  &__add-button {
    align-items: center;
    color: #1c262f;

    &--disabled {
      color: #9aa8b5;
    }
  }

  &__delete-button {
    align-self: start;
    margin-top: 18px;
    color: #db000b;

    &--disabled {
      color: #9aa8b5;
      pointer-events: none;
    }
  }

  &__add-text {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
  }
}
</style>
