<template>
    <CBadge class="task-badge" :size="size" :color="defineBadgeColor()">{{ status }}</CBadge>
</template>

<script>
export default {
    props: {
        status: {
            required: true,
            type: String
        },
        size: {
            type: String,
            default: 'sm',
            validator: (value) => ['sm'].includes(value)
        }
    },
    methods: {
        defineBadgeColor() {
            if (['Fully Executed', 'Completed'].includes(this.status)) {
                return 'success';
            }

            if (['Revoked', 'Cancelled'].includes(this.status)) {
                return 'danger';
            }

            return 'warning';
        }
    }
}
</script>

<style scoped lang="scss">
.task-badge {
    font-size: 11px;
}
</style>
