<template>
  <div class="c-chat-message">
    <div class="c-chat-message__header">
      <div class="c-chat-message__user">
        <Avatar
          :size="24"
          class="c-chat-message__user-logo"
          :rounded="false"
          :src="userPhoto"
          :display-name="[message.user.first_name, message.user.last_name]" />
        <div class="c-chat-message__user-name">
          {{ userName }}

          <span
            v-if="message.edited && !message.deleted"
            class="c-chat-message__edited"
            >Edited</span
          >
        </div>
      </div>

      <div class="c-chat-message__date">
        <div v-if="!marked" class="c-chat-message__unread" />

        {{ $DwT(message.created_at) }}
      </div>
    </div>

    <div
      v-if="!message.deleted"
      class="c-chat-message__body"
      v-html="htmlMessage"
    ></div>
    <div v-else class="c-chat-message__body c-chat-message__body--deleted">
      Message removed
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar.vue";

export default {
  name: 'CChatMessage',
  components: { Avatar },
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    marked: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: [Object, Array],
      default: () => ([])
    },
    user: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    htmlMessage() {
      const words = this.message.content.replaceAll(/\n/g, " ").split(' ');
      const companyId = this.user.company.id
      const taggedUsers = this.message.tagged_users

      function wrapTag(tag) {
        let user
        let url
        if (taggedUsers && taggedUsers.length) {
          user = taggedUsers.filter(user => user.value === tag.slice(1));
        }
        if (Array.isArray(user) && user.length) {
          url = `/companies/${ companyId }/users/${ user[0].id }/info`
        }
        return `<a href="${ url ? url : '#' }" class="c-chat-message__tag">${ tag }</a>`
      }

      const tags = words.map(word => {
        if (word.startsWith('@') && word.length > 1) {
          if (word.indexOf('@') !== word.lastIndexOf('@')) {
            const secondOccurence = word.slice(1).indexOf('@') + 1
            return `${ wrapTag(word.slice(0, secondOccurence)) + word.slice(secondOccurence) }`
          }

          return wrapTag(word)
        }

        return word
      })

      return tags.join(' ')
    },
    userName() {
      return this.message.user?.full_name || this.message.user?.first_name
    },
    userPhoto() {
      return this.message.user?.photo || ''
    },
  },
}
</script>

<style lang="scss">
.c-chat-message {
  padding: 16px;

  &__tag {
    background: #0068ad40;
    font-weight: bold;
  }

  &__header,
  &__user,
  &__date {
    display: flex;
    align-items: center;
  }

  &__header {
    justify-content: space-between;
  }

  &__edited {
    margin-left: 8px;
    color: #677a89;
    font-size: 9px;
  }

  &__user-logo {
    width: 24px;
    height: 24px;
    //background-color: #677a89;
    margin-right: 8px;
    background-position: center center;
    background-size: cover;
  }

  &__user-name {
    font-size: 12px;
    line-height: 20px;
    color: #0068ad;
    display: flex;
    align-items: center;
  }

  &__unread {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #db000b;
    margin-right: 4px;
  }

  &__date {
    flex-shrink: 0;
    font-size: 8px;
    line-height: 11px;
    color: #677a89;
  }

  &__body {
    margin-top: 8px;
    padding: 0 24px;
    font-size: 12px;
    line-height: 20px;
    color: #1c262f;
    white-space: break-spaces;

    &--deleted {
      color: rgba(28, 38, 47, 0.5);
      font-style: italic;
    }
  }
}
</style>
