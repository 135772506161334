<template>
  <div class="users-approval-list">
    <div class="users-approval-list__title">
      <span class="users-approval-list__title-text">Approve</span>

      <span class="users-approval-list__title-text">Reject</span>
    </div>

    <ul class="users-approval-list__list">
      <li
        v-for="(user, index) in users"
        :key="index + user.id"
        class="users-approval-list__list-item"
      >
        <div class="users-approval-list__avatar-wrapper">
          <Avatar
            :size="32"
            class="users-approval-list__avatar"
            :src="user.photo"
            :display-name="[user.first_name, user.last_name]" />
        </div>

        <span class="users-approval-list__name">{{ user.full_name }}</span>

        <div
          class="users-approval-list__label"
          :class="{
            'users-approval-list__label--active':
              visibleActions && (user.approved || user.rejected),
          }"
        >
          <span class="users-approval-list__label-text">{{
            user.is_required ? 'Required' : 'Optional'
          }}</span>
        </div>

        <div class="users-approval-list__actions">
          <template v-if="visibleActions">
            <button
              type="button"
              class="users-approval-list__button"
              :class="{
                'users-approval-list__button--approved': user.approved,
              }"
              :disabled="disableActionButton || !isCurrentUser(user)"
              @click="approve(user.approved)"
            >
              <CIcon
                class="users-approval-list__actions-item users-approval-list__icon users-approval-list__icon--approve"
                name="cilCheckAlt"
              />
            </button>

            <button
              type="button"
              class="users-approval-list__button"
              :class="{
                'users-approval-list__button--rejected': user.rejected,
              }"
              :disabled="disableActionButton || !isCurrentUser(user)"
              @click="reject(user.rejected)"
            >
              <CIcon
                class="users-approval-list__icon users-approval-list__icon--reject"
                name="cilX"
              />
            </button>
          </template>

          <template v-else>
            <span class="users-approval-list__na">N/A</span>

            <span class="users-approval-list__na">N/A</span>
          </template>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Avatar from "@/components/Avatar.vue";

export default {
  name: 'UsersApprovalList',
  components: { Avatar },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    visibleActions: {
      type: Boolean,
      default: false,
    },
    disableActionButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['approveHandler', 'rejectHandler'],
  computed: {
    ...mapState({
      authorizedUser: (state) => state.app.user,
    }),
  },
  methods: {
    isCurrentUser(user) {
      const userId = user.user_id || user.id
      return this.authorizedUser.id === userId
    },

    approve(isApproved) {
      if (isApproved) return
      this.$emit('approveHandler')
    },
    reject(isRejected) {
      if (isRejected) return
      this.$emit('rejectHandler')
    },
  },
}
</script>

<style scoped lang="scss">
.users-approval-list {
  &__list {
    list-style: none;
    padding: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 4px 16px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 12px;
  }

  &__title-text {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #677a89;
    text-transform: uppercase;

    &:first-child {
      margin-right: 10px;
    }
  }

  &__list-item {
    display: grid;
    grid-template-areas: 'avatar name actions' 'avatar label actions';
    grid-template-columns: auto 1fr auto;
    gap: 4px 8px;
    padding: 12px 25px 12px 0;
  }

  &__avatar-wrapper {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    align-self: center;
    grid-area: avatar;
    background-color: #c3cdd5;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__name {
    grid-area: name;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.003em;
    color: #1c262f;
  }

  &__label {
    grid-area: label;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c3cdd5;
    border-radius: 24px;
    margin-right: auto;
    padding: 0 8px;

    &--active {
      background-color: #005694;
    }
  }

  &__label-text {
    color: #ffffff;
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
  }

  &__actions {
    grid-area: actions;
    display: flex;
    padding-top: 7px;
  }

  &__na {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #9aa8b5;

    &:first-child {
      margin-right: 24px;
    }
  }

  &__button {
    width: 17px;
    height: 17px;
    background-color: #9aa8b5;
    padding: 3px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 100%;
    overflow: hidden;

    &:first-child {
      margin-right: 24px;
    }

    &--rejected {
      background-color: #ff5257;
    }

    &--approved {
      background-color: #00a383;
    }
  }

  &__icon {
    &--approve {
    }

    &--reject {
    }
  }
}
</style>
