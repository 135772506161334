<template>
  <div ref="list" class="c-chat-messages">
    <div v-if="loadingData" class="c-chat-messages__loader">
      <CSpinner color="secondary" />
    </div>

    <template v-else>
      <div v-if="!messages.length" class="c-chat-messages__empty">
        <CIcon
          class="c-chat-messages__icon"
          name="cilCommentBubble"
          size="custom-size"
        />

        <h3 class="c-chat-messages__font c-chat-messages__font--h3">
          No messages yet
        </h3>

        <p class="c-chat-messages__font c-chat-messages__font--p">
          Be the first to post a message
        </p>
      </div>

      <template v-else>
        <div
          v-for="message in messages"
          :key="message.id"
          ref="messages"
          :data-id="message.id"
          :data-unread="!isMarked(message)"
          class="c-chat-messages__item"
        >
          <CChatMessage
            class="c-chat-messages__message"
            :message="message"
            :marked="isMarked(message)"
            :user="user"
            :tags="tags"
          />

          <div
            v-if="showActionButtons(message)"
            class="c-chat-messages__action"
          >
            <CButton
              class="c-chat-messages__action-item"
              color="secondary"
              variant="outline"
              size="sm"
              @click="emitEdit(message)"
            >
              <CIcon name="cil-pencil" size="sm" />
            </CButton>

            <CButton
              class="c-chat-messages__action-item"
              color="secondary"
              variant="outline"
              size="sm"
              @click="emitDelete(message)"
            >
              <CIcon name="cil-trash" size="sm" />
            </CButton>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import CChatMessage from '@/components/CChat/CChatMessage'
export default {
  name: 'CChatMessages',
  components: { CChatMessage },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    loadingData: {
      type: Boolean,
      default: false,
    },
    actionsPending: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    tags: {
      type: [Object, Array],
      default: () => ([])
    }
  },
  emits: ['edit', 'delete'],
  methods: {
    isMyMessage(message) {
      return this.user.id === message.user.id
    },
    isMarked(message) {
      return message.marked_user_ids?.includes(this.user.id)
    },

    showActionButtons(message) {
      return !message.deleted && this.isMyMessage(message)
    },

    emitEdit(message) {
      if (this.actionsPending) return
      this.$emit('edit', message)
    },
    emitDelete(message) {
      if (this.actionsPending) return
      this.$emit('delete', message)
    },
  },
}
</script>

<style lang="scss">
.c-chat-messages {
  position: relative;
  height: 100%;

  $parent: &;

  &__loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    position: relative;

    &:hover {
      #{$parent} {
        &__action {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:nth-child(odd) {
      background-color: #f2f5f8;
    }
  }

  &__action {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0;
    visibility: hidden;
  }

  &__action-item {
    background-color: #fff;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__icon {
    color: #b1b7c1;
    height: 37px;
    width: 37px;
  }

  &__font {
    &--h3 {
      color: #677a89;
      font-size: 18px;
      font-weight: 700;
    }

    &--p {
      color: #b1b7c1;
      font-size: 14px;
    }
  }
}
</style>
