<template>
  <div class="c-chat-footer">
    <div
      v-if="editingMessage && editingMessage.content"
      class="c-chat-footer__editing"
    >
      <div class="c-chat-footer__content">
        <h4 class="c-chat-footer__font c-chat-footer__font--h4">Editing</h4>

        <p
          class="c-chat-footer__font c-chat-footer__font--p"
          v-html="editingMessage.content"
        />
      </div>

      <button
        type="button"
        class="c-chat-footer__button c-chat-footer__button--send"
        @click="closeEditing"
      >
        <CIcon name="cilX" class="c-chat-footer__icon c-chat-footer__icon--x" />
      </button>
    </div>

    <div class="c-chat-footer__message">
      <Mentionable
        insert-space
        :limit="50"
        :keys="['@']"
        :items="tags"
        @open="tagsPopover = true"
        @close="tagsPopover = false">
      <template #item-@="{ item }">
        <div class="user">
          <span class="c-chat-footer__tagValue">
             @{{ item.value }}
          </span>
          <span class="c-chat-footer_tagLabel">
           ({{ item.full_name }})
          </span>
        </div>
      </template>
      <div class="c-chat-footer__ghost">{{ modelValue }}</div>

      <textarea
        ref="textarea"
        v-model="localValue"
        class="c-chat-footer__field"
        placeholder="To write a message..."
        @keydown.enter.exact.prevent="emitMessage"
        @keydown.ctrl.enter.exact.prevent="addNewRow"
      />

      <button
        type="button"
        class="c-chat-footer__button c-chat-footer__button--send"
        @click="emitMessage"
      >
        <CSpinner v-if="sendingMessage" color="secondary" size="sm" />

        <CIcon
          v-else
          name="cilPaperPlane"
          size="lg"
          class="c-chat-footer__icon c-chat-footer__icon--send"
        />
      </button>
      </Mentionable>
    </div>
  </div>
</template>

<script>
import { Mentionable } from 'vue-mention'
export default {
  name: 'CChatFooter',
  components: {
    Mentionable,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    sendingMessage: {
      type: Boolean,
      default: false,
    },
    editingMessage: {
      type: Object,
      default: () => ({}),
    },
    tags: {
      type: [Object, Array],
      default: () => ([])
    }
  },
  emits: ['update:modelValue', 'sendMessage', 'closeEditing'],
  data() {
    return {
      selectedTags: [],
      tagsPopover: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  methods: {
    getTags() {
      const regex = /(?:^|\s)@[\w.]+/g;
      const tags = this.localValue.match(regex);
      if (tags && tags.length) {
        const trimmedTags = tags.map(tag => tag.trim().replace('@', ''))
        const filteredTags = this.tags.filter(tagObj => {
          return trimmedTags.includes(tagObj.value)
        })
        return filteredTags
      }
      return []
    },
    emitMessage() {
      if (this.sendingMessage || this.tagsPopover) return
      this.$emit('sendMessage', this.getTags())
    },
    addNewRow() {
      this.localValue = this.localValue + '\n'
      this.$refs.textarea.focus()
    },
    closeEditing() {
      this.$emit('closeEditing')
    },
  },
}
</script>

<style lang="scss">
.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: #0068AD;
  color: white;
}

.resize-observer[data-v-b329ee4c]{
  position:absolute;
  top:0;
  left:0;
  z-index:-1;
  width:100%;
  height:100%;
  border:none;
  background-color:transparent;
  pointer-events:none;
  display:block;
  overflow:hidden;
  opacity:0
}
.resize-observer[data-v-b329ee4c] object{
  display:block;
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  overflow:hidden;
  pointer-events:none;
  z-index:-1
}
.v-popper__popper{
  z-index:10000;
  top:-10px;
  left:0;
  outline:none;
  font-size: 12px;
}
.v-popper__popper.v-popper__popper--hidden{
  visibility:hidden;
  opacity:0;
  transition:opacity .15s,visibility .15s;
  pointer-events:none
}
.v-popper__popper.v-popper__popper--shown{
  visibility:visible;
  opacity:1;
  transition:opacity .15s
}
.v-popper__popper.v-popper__popper--skip-transition,.v-popper__popper.v-popper__popper--skip-transition>.v-popper__wrapper{
  transition:none!important
}
.v-popper__backdrop{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:none
}
.v-popper__inner{
  padding: 10px;
  position:relative;
  box-sizing:border-box;
  overflow-y:auto
}
.v-popper__inner>div{
  position:relative;
  z-index:1;
  max-width:inherit;
  max-height:inherit
}
.v-popper__arrow-container{
  position:absolute;
  width:10px;
  height:10px
}
.v-popper__popper--arrow-overflow .v-popper__arrow-container,.v-popper__popper--no-positioning .v-popper__arrow-container{
  display:none
}
.v-popper__arrow-inner,.v-popper__arrow-outer{
  border-style:solid;
  position:absolute;
  top:0;
  left:0;
  width:0;
  height:0
}
.v-popper__arrow-inner{
  visibility:hidden;
  border-width:7px
}
.v-popper__arrow-outer{
  border-width:6px
}
.v-popper__popper[data-popper-placement^=top] .v-popper__arrow-inner,.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-inner{
  left:-2px
}
.v-popper__popper[data-popper-placement^=top] .v-popper__arrow-outer,.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-outer{
  left:-1px
}
.v-popper__popper[data-popper-placement^=top] .v-popper__arrow-inner,.v-popper__popper[data-popper-placement^=top] .v-popper__arrow-outer{
  border-bottom-width:0;
  border-left-color:transparent!important;
  border-right-color:transparent!important;
  border-bottom-color:transparent!important
}
.v-popper__popper[data-popper-placement^=top] .v-popper__arrow-inner{
  top:-2px
}
.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-container{
  top:0
}
.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-inner,.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-outer{
  border-top-width:0;
  border-left-color:transparent!important;
  border-right-color:transparent!important;
  border-top-color:transparent!important
}
.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-inner{
  top:-4px
}
.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-outer{
  top:-6px
}
.v-popper__popper[data-popper-placement^=left] .v-popper__arrow-inner,.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-inner{
  top:-2px
}
.v-popper__popper[data-popper-placement^=left] .v-popper__arrow-outer,.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-outer{
  top:-1px
}
.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-inner,.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-outer{
  border-left-width:0;
  border-left-color:transparent!important;
  border-top-color:transparent!important;
  border-bottom-color:transparent!important
}
.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-inner{
  left:-4px
}
.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-outer{
  left:-6px
}
.v-popper__popper[data-popper-placement^=left] .v-popper__arrow-container{
  right:-10px
}
.v-popper__popper[data-popper-placement^=left] .v-popper__arrow-inner,.v-popper__popper[data-popper-placement^=left] .v-popper__arrow-outer{
  border-right-width:0;
  border-top-color:transparent!important;
  border-right-color:transparent!important;
  border-bottom-color:transparent!important
}
.v-popper__popper[data-popper-placement^=left] .v-popper__arrow-inner{
  left:-2px
}
.v-popper--theme-dropdown .v-popper__inner{
  background:#fff;
  color:black;
  border-radius:6px;
  border:1px solid #ddd;
  box-shadow:0 6px 30px #0000001a
}
.v-popper--theme-dropdown .v-popper__arrow-inner{
  visibility:visible;
  border-color:#fff
}
.v-popper--theme-dropdown .v-popper__arrow-outer{
  border-color:#ddd
}
.v-popper--theme-tooltip .v-popper__inner{
  background:rgba(0,0,0,.8);
  color:#fff;
  border-radius:6px;
  padding:7px 12px 6px
}
.v-popper--theme-tooltip .v-popper__arrow-outer{
  border-color:#000c
}


.c-chat-footer {
  &__tagValue {
    font-weight: bold;
  }

  &__editing {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px 10px 16px;
    border-top: 1px solid #677a89;
    z-index: 2;
  }

  &__font {
    margin: 0;

    &--h4 {
      font-size: 12px;
      font-weight: 600;
      color: #0068ad;
    }

    &--p {
      font-size: 10px;
      color: #677a89;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__message {
    position: relative;
  }

  &__button {
    border: none;
    background-color: transparent;
    &--send {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      z-index: 3;
    }
  }

  &__field,
  &__ghost {
    width: 100%;
    min-height: 50px;
    max-height: 100px;
    padding: 10px 40px 10px 16px;
    margin: 0;
    display: block;
    border: none;
    border-top: 2px solid #677a89;
    outline: none;
    font-size: 14px;
    line-height: 20px;
  }

  &__field {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    resize: none;
    color: #1c262f;
    z-index: 2;
  }

  &__ghost {
    opacity: 0;
    pointer-events: none;
    user-select: none;
    white-space: pre-wrap;
  }
}
</style>
