<template>
    <CModal class="common-modal overflow-modal" :visible="visible" backdrop="static">
        <div class="common-modal__content" :class="[contentClass]">
            <div class="common-modal__close-bar">
                <CIcon name="cil-x" @click="$emit('close')"/>
            </div>

            <div class="common-modal__body">
                <div class="overflow-modal__title-wrapper">
                    <h4 class="common-modal__title">{{ title }}</h4>
                    <p class="common-modal__text" v-html="text"></p>
                </div>

                <div>
                    <slot></slot>
                </div>

                <div class="common-modal__buttons">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </CModal>
</template>

<script>

export default {
    name: "AppModal",
    props: ['visible', 'icon', 'title', 'text', 'contentClass']
}
</script>

<style scoped lang="scss">
.common-modal {

    &__close-bar {
        display: flex;
        justify-content: flex-end;

        & svg {
            height: 15px !important;
            width: 15px !important;
            color: rgba(159, 175, 188, 1);
            cursor: pointer;

            &:hover {
                color: black;
            }
        }
    }

    &__title {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }

    &__text {
        font-size: 16px;
        color: #677A89;
        text-align: center;
    }

    &__content {
        padding: 40px;
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    &__buttons {
        display: flex;
        justify-content: center;
        gap: 16px;
    }
}
</style>
